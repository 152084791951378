.ManageBills {
  padding: 80px 0;
  display: grid;
  place-items: center;
  background-color: #f5f1eb;
}

.ManageBills h1 {
  margin-bottom: 20px;
}

.ManageBills table {
  margin-top: 20px;
}

.ManageBills table th,
.ManageBills table td {
  min-width: 150px;
  max-width: 400px;
  word-wrap: break-word;
  height: 50px;
  font-size: 18px;
  padding: 15px;
  border-top: 1px solid black;
  border-bottom: none;
  vertical-align: middle;
}

.ManageBills table th {
  color: white;
  background-color: #dd7802;
}

.ManageBills table td {
  color: black;
  background-color: #dfc7ad;
}

.overlay {
  height: 100vh !important;
  max-height: 100vh !important;
}

.loadMore {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.loadMore:focus {
  outline: none;
}

@media screen and (max-width: 750px) {
  .ManageBills {
    padding: 20px;
  }
}

@media screen and (max-width: 605px) {
  .ManageBills {
    padding: 20px;
    font-size: 20px;
  }
}
