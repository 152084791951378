.button {
  transition: background-color 0.4s;
  background-color: #c5e7c0;
  border: 2px solid #dd7802;
  padding: 10px;
  margin: 10px 0;
}

.button:focus {
  outline: 3px dotted #dd7802;
}

.button:hover {
  background-color: #a5d39e;
}
