.AdminHeader {
  height: 87px;
  width: 100%;
  background-color: #dd7802;
  display: flex;
  justify-content: space-between;
}

.dropdownHeader button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.dropdownHeader button {
  background-color: transparent !important;
  border: none;
  font-size: 18px;
}

.dropdownHeader a {
  padding: 22px !important;
  margin: 0px !important;
  transition: 0.4s;
}

.dropdownHeader div {
  padding: 0;
}

.dropdownHeader a:hover {
  color: black !important;
  background-color: #dfc7ad !important;
}

.AdminHeader > :is(nav, div) {
  padding: 8px 16px;
  display: flex;
  height: 100%;
}

.AdminHeader > nav {
  width: 81%;
}

.AdminHeader > div {
  width: auto;
}

.logOut {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.logOut:focus {
  outline: none;
}

.AdminHeader > :is(nav, div) > * {
  transition: 0.4s;
  color: white;
  margin-right: 40px;
  display: grid;
  place-items: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.AdminHeader > :is(nav, div) *:hover {
  text-decoration: none;
  color: #c7cdd2;
}

.logo {
  margin-top: -8px;
}

@media screen and (max-width: 856px) {
  .logOut {
    margin-bottom: 10px;
  }

  .AdminHeader {
    height: auto;
    flex-direction: column;
  }

  .AdminHeader > nav {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .AdminHeader > nav * {
    margin-bottom: 20px;
  }

  .AdminHeader > :is(nav, div) > :is(a, span) {
    padding-left: 12px !important;
  }

  .dropdownHeader {
    margin: 0 !important;
  }
}
