.controlPanel {
  display: flex;
  flex-direction: row-reverse;
}

.controlPanel,
.searchPanel,
.tableContainer,
.filterPanel {
  width: 80vw;
}

.searchPanel {
  display: flex;
  align-items: center;
}

.filtersContainer {
  transition: 0.4s;
  cursor: pointer;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7d5c0;
  border: 2px solid #dd7802;
  border-left: none;
  padding: 9px;
}

.filtersContainer > span {
  margin-right: 7px;
}

.filtersContainer > i {
  color: #dd7802;
  transition: 0.4s;
}

.filtersContainer:hover {
  background-color: #ebe4dc;
}

.filtersContainer:focus {
  background-color: #ebe4dc;
  outline: none;
}

.filtersContainer:hover > i {
  color: #aa5b01;
}

.exportContainer {
  transition: 0.4s;
  cursor: pointer;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7d5c0;
  border: 2px solid #dd7802;
  padding: 9px;
  margin-left: auto;
}

.exportContainer > span {
  margin-right: 7px;
}

.exportContainer > i {
  color: #dd7802;
  transition: 0.4s;
}

.exportContainer:hover {
  background-color: #ebe4dc;
}

.exportContainer:focus {
  background-color: #ebe4dc;
  outline: none;
}

.exportContainer:hover > i {
  color: #aa5b01;
}
.search {
  width: 100%;
  transition: 0.4s;
  background-color: #e7d5c0;
  padding: 9px;
  padding-right: 35px;
  box-sizing: border-box;
  border: 2px solid #dd7802;
  border-right-width: 1px;
}

.searchContainer {
  position: relative;
  width: 300px;
}

.searchContainer i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #dd7802;
}

.search:focus {
  background-color: #ebe4dc;
  outline: none;
}

.filterPanel {
  display: flex;
  flex-wrap: wrap;
}

.filter {
  background-color: #e7d5c0;
  border: 1px solid #dd7802;
  margin: 5px;
  padding: 5px;
}

.filter > span {
  font-weight: bold;
}

.addButton {
  transition: background-color 0.4s;
  background-color: #c5e7c0;
  border: 2px solid #dd7802;
  padding: 10px;
  min-width: 150px;
}

.editButton,
.deleteButton,
.showButton {
  margin-right: 5px;
  position: relative;
  transition: color 0.4s;
  font-size: 24px;
  padding: 0;
  color: #dd7802;
  background-color: transparent;
  border: none;
}

.editButton::after,
.deleteButton::after,
.showButton::after {
  transition: 0.4s;
  font-size: 15px;
  opacity: 0;
  visibility: hidden;
  padding: 5px;
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  top: -20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.showButton::after {
  content: "Preview";
  width: 100px;
}

.editButton::after {
  content: "Click to edit";
  width: 100px;
}

.deleteButton::after {
  content: "Click to delete";
  width: 120px;
}

.banButton::after {
  content: "Click to block";
  width: 120px;
}

.unblockButton::after {
  content: "Click to unblock";
  width: 120px;
}

.resetWrongPasswordButton::after {
  content: "Click to reset Password";
  width: 120px;
}

.editButton:hover,
.deleteButton:hover,
.showButton:hover {
  color: #aa5b01;
}

.editButton:hover::after,
.deleteButton:hover::after,
.showButton:hover::after,
.editButton:focus::after,
.deleteButton:focus::after,
.showButton:focus::after {
  top: -50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  visibility: visible;
}

.addButton:focus,
.editButton:focus,
.deleteButton:focus,
.showButton:focus {
  outline: 3px dotted #dd7802;
}

.addButton:hover {
  background-color: #a5d39e;
}

.tableContainer {
  margin-top: 20px;
}

.tableContainer,
.tableContainer > * {
  transform: rotateX(180deg);
}

.admin-logo {
  width: 180px;
  height: 50px;
  display: grid;
  place-items: center;
}

.admin-logo > img {
  width: 140px;
}

@media screen and (max-width: 750px) {
  .tableContainer,
  .controlPanel,
  .searchPanel,
  .filterPanel {
    width: 100%;
  }

  .controlPanel > *,
  .searchPanel > *,
  .search {
    min-height: 48px;
  }
}

@media screen and (max-width: 605px) {
  .controlPanel,
  .searchPanel {
    flex-direction: column;
  }

  .filtersContainer,
  .search {
    border: 2px solid #dd7802;
  }

  .searchPanel > *:nth-child(n + 1) {
    border-top: none;
  }

  .controlPanel > *,
  .searchPanel > * {
    width: 100%;
  }
}

.dropdown-menu.admin_dropdown-menu {
  height: auto !important;
  width: 178px;
  margin: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.13);
  top: 4.2222rem !important;
  left: -4.1111rem !important;
  border-radius: 30px;
  transform: none !important;
}

.dropdown-menu.admin_dropdown-menu .admin_dropdown-item:first-child {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.dropdown-menu.admin_dropdown-menu .admin_dropdown-item:last-child {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.dropdown-menu.admin_dropdown-item {
  padding: 0;
  cursor: pointer;
  text-transform: capitalize;
  color: #16151c;
}

.dropdown-menu.admin_dropdown-item span {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}

.dropdown-menu.admin_dropdown-item i {
  font-size: 1.7rem;
  margin-right: 10px;
  color: #1f365c;
}
