.select {
  cursor: pointer;
  transition: 0.4s !important;
  background-color: #e7d5c0 !important;
  padding: 4px 0 4px 4px !important;
  border: none !important;
  min-height: 0 !important;
  width: 100%;
  max-width: 530px;
  outline: 1px solid #dd7802 !important;
}

.select > div > div {
  background-color: #e7d5c0 !important;
  border-top: 1px solid #dd7802 !important;
}

.select > a {
  background-color: #e7d5c0 !important;
  outline: 2px solid #dd7802 !important;
  margin: 7px !important;
}

.select:focus {
  outline: 1px dotted #dd7802 !important;
}

.clear {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  position: absolute;
  background-color: #e7c0c0;
  cursor: pointer;
  padding: 1px;
}

.selectWrapper {
  position: relative;
  margin-bottom: 20px;
}
