.input {
  transition: 0.4s;
  margin-bottom: 20px;
  background-color: #e7d5c0;
  padding: 9px;
  border: none;
  outline: 1px solid #dd7802;
}

.input:focus {
  background-color: #ebe4dc;
}
