.SidebarForm {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f1eb;
  padding: 18px;
  border: 5px solid #dd7802;
}

.SidebarForm h2 {
  padding-bottom: 15px;
  border-bottom: 2px solid #dd7802;
  margin-bottom: 15px;
}
